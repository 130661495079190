import {Alert, Box, Button, CheckIcon, Grid, Group, Radio, Stack, Text} from "@mantine/core";
import {IconCheck, IconX} from "@tabler/icons-react";
import Modal, { Props as ModalProps } from "./Modal";
import { client } from "../../lib/axiosClient";
import {Notyf} from "notyf";
import { useState } from "react";
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

const cookies = new Cookies(null, { path: '/' });
const {Col} = Grid

type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;

const listStatus = [
    {label: "تاكيد", value: "confirmed"},
    {label: "المحاولة الاولى", value: "no_answer"},
    {label: "المحاولة الثانية", value: "no_answer_2"},
    {label: "المحاولة الثالثة", value: "no_answer_3"},
    {label: "إبلاغ", value: "reported"},
    {label: "مكرر", value: "duplicated"},
    {label: "الهاتف المغلق", value: "phone_closed"},
    {label: "الهاتف خطأ", value: "wrong_number"},
    {label: "ملغي", value: "cancelled"},
    {label: "مزؤر", value: "fake_order"},
    {label: "تم التوصيل", value: "delivred"},
    {label: "مسترجع", value: "returned"},
    {label: "تم الرفع", value: "uploaded"},
    {label: "مسترجع للتحقق", value: "returned_checked"},
    {label: "عند شركة الشحن", value: "shipping_company"},
    {label: "رفض", value: "closed"}
]

export const SelectStatus = ({data, ...props}: Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const onFormSubmit = () => {
        setLoading(true)
        const notyf = new Notyf({ position: { x: "right", y: "bottom" } });

        client.delete(`/orders/${data}`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: t('modals.deleteOrder.alert01'), color: '#fff' });
            setLoading(false)
            typeof props?.refetchData == "function" && props?.refetchData()
            closeModal()
        })
        .catch((error) => {
            notifications.show({ message: t('modals.deleteOrder.alert02'), color: '#fff' });
            setLoading(false)
            closeModal()
        });
    };

    const closeModal = () => {
        props.onClose();
        setLoading(false)
    };

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading} size="md"
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button variant="outline" color="gray" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} onClick={onFormSubmit}>تاكيد</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={({ colors }) => ({padding: 20})}>
                <Grid gutter={0}>
                    <Grid.Col span={{ base: 12 }}>
                        <Radio.Group name="favoriteFramework" withAsterisk >
                            <Stack mt="xs">
                                {listStatus.map((item, index: number) => (
                                    <Radio
                                        key={index} icon={CheckIcon}
                                        value={item?.value} color={"#323232"}
                                        label={
                                            <Group justify='space-between'>
                                                <Text fw={700} c={"gray.7"} >{item?.label}</Text>
                                            </Group>
                                        }
                                        styles={()=>({
                                            root: {
                                                background: "#fff",
                                                border: `2px solid ${"#323232"}7d`,
                                                borderRadius: 5,
                                                padding: "8px 10px"
                                            },
                                            body: { justifyContent: "flex-start" },
                                            labelWrapper: { width: "100%" },
                                            label: { width: "100%" }
                                        })}
                                    />
                                ))}
                            </Stack>
                        </Radio.Group>
                    </Grid.Col>
                </Grid>
            </Box>
        </Modal>
    );
};