import React, { useEffect, useState } from 'react';
import { client } from '../../lib/axiosClient';
import { useDisclosure } from '@mantine/hooks';
import { HeadPage } from '../../componants';
import Cookies from 'universal-cookie';
import classes from './../../styles/Product.module.css';
import { useCategories, useLandingPage, useProducts, useReportsDelivery, useReportsDeliveryCompany, useReportsMoney, useReportsProducts, useUsers } from '../../api';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Button, Container, Group, Popover, SegmentedControl, Select, Stack, Tabs, Text } from '@mantine/core';
import { IconBox, IconCalendar, IconFilter, IconLock, IconMapBolt, IconMapPin, IconUser, IconX } from '@tabler/icons-react';
import { DatePickerInput } from '@mantine/dates';
import { useNavigate, useParams } from 'react-router-dom';
import { DeliveryReportsTable, DeliveryCompanyReportsTable, ProductsReportsTable, MoneyReportsTable } from '../../componants/Tables';
import { useForm } from '@mantine/form';
import dayjs from 'dayjs';
import Wilayas from './../../helper/wilayas.json';
const cookies = new Cookies(null, { path: '/' });

export function Reports () {
    const { t } = useTranslation();
    const {tabs} = useParams<{tabs: string}>();
    const navigate = useNavigate();
    const [openedPopover, setOpenedPopover] = useState(false);
    const [allWilayas, setAllWilayas] = useState<{label: string, value: string}[]>([])

    const [reportsDelivery, setReportsDelivery] = useState<any>(null);
    const [reportsDeliveryCompany, setReportsDeliveryCompany] = useState<any>(null);
    const [reportsProducts, setReportsProducts] = useState<any>(null);
    const [reportsMoney, setReportsMoney] = useState<any>(null);
    const [products, setProducts] = useState<{label: string, value: string}[]>([]);
    const [users, setUsers] = useState<{label: string, value: string}[]>([]);

    const {onSubmit, reset, getInputProps, setValues, values} = useForm({
        initialValues: {
            productId: "", state: "", date: [], status: "", confirmedBy: ""
        }
    });

    const {fetchData: getReportsDelivery , loading: loadingReportsDelivery, data: dataReportsDelivery} = useReportsDelivery()
    const {fetchData: getReportsDeliveryCompany , loading: loadingReportsDeliveryCompany, data: dataReportsDeliveryCompany} = useReportsDeliveryCompany()
    const {fetchData: getReportsProducts , loading: loadingReportsProducts, data: dataReportsProducts} = useReportsProducts()
    const {fetchData: getReportsMoney, loading: loadingReportsMoney, data: dataReportsMoney} = useReportsMoney()
    const {loading: loadingProducts, data: dataProducts} = useProducts({
        idStore: cookies.get("id") || ""
    })
    const {loading: loadingLandingProducts, data: dataLandingProducts} = useLandingPage({
        idStore: cookies.get("id") || ""
    })
    const {loading: loadingUsers, data: dataUsers} = useUsers()

    const [role, setRole] = useState("");

    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])

    useEffect(() => {
        if (Wilayas.length > 0) {
            let newData: {label: string, value: string}[] = []
            Wilayas.map((item: any) => {
                newData.push({label: item.name, value: item.id.toString()})
            })
            setAllWilayas(newData)
        }
    }, [])

    useEffect(() => {
        if (dataProducts) {
            const arrayProducts: {label: string, value: string}[] = []
            for (let i = 0; i < dataProducts?.length; i++) {
                const element = dataProducts[i];
                if (products.findIndex((item: any) => item.value === element?._id) < 0) {
                    arrayProducts.push({label: element?.name, value: element?._id})
                }
            }
            
            setProducts([...products, ...arrayProducts])
        }
    }, [dataProducts])

    useEffect(() => {
        if (dataLandingProducts) {
            const arrayLandingProducts: {label: string, value: string}[] = []
            for (let i = 0; i < dataLandingProducts?.length; i++) {
                const element = dataLandingProducts[i];
                if (products.findIndex((item: any) => item.value === element?._id) < 0) {
                    arrayLandingProducts.push({label: element?.name, value: element?._id})
                }
            }
            setProducts([...products, ...arrayLandingProducts])
        }
    }, [dataLandingProducts])

    useEffect(() => {
        if (dataUsers) {
            const arrayUsers: {label: string, value: string}[] = []
            for (let i = 0; i < dataUsers?.length; i++) {
                const element = dataUsers[i];
                if (arrayUsers.findIndex((item: any) => item.value === element?._id) < 0 && element?.role === "confirmed") {
                    arrayUsers.push({label: element?.name, value: element?._id})
                }
            }
            setUsers([...arrayUsers])
        }
    }, [dataUsers])

    useEffect(() => {
        if (tabs === "orders") {
            getReportsDelivery({idStore: cookies.get("id") || ""})
        } else if (tabs === "delivery_company") {
            getReportsDeliveryCompany({idStore: cookies.get("id") || ""})
        } else if (tabs === "products") {
            getReportsProducts({idStore: cookies.get("id") || ""})
        } else if (tabs === "money") {
            getReportsMoney({idStore: cookies.get("id") || ""})
        }
    }, [tabs])
    
    useEffect(() => {
        if (dataReportsDelivery) {
            setReportsDelivery(dataReportsDelivery)
        }
    }, [dataReportsDelivery])
    
    useEffect(() => {
        if (dataReportsDeliveryCompany) {
            setReportsDeliveryCompany(dataReportsDeliveryCompany)
        }
    }, [dataReportsDeliveryCompany])
    
    useEffect(() => {
        if (dataReportsProducts) {
            setReportsProducts(dataReportsProducts)
        }
    }, [dataReportsProducts])
    
    useEffect(() => {
        if (dataReportsMoney) {
            setReportsMoney(dataReportsMoney)
        }
    }, [dataReportsMoney])

    const onFilter = ({productId, state, date, status, confirmedBy}: any) => {
        const query: any = {idStore: cookies.get("id") || ""};
        if (productId) query.idProduct = productId;
        if (state) query.state = state;
        
        if (date && date?.length === 2 && date?.[0] && date?.[1]) {
            query.dateStart = dayjs(date?.[0]).format("YYYY-MM-DD")
            query.dateEnd = dayjs(date?.[1]).format("YYYY-MM-DD")
        }
        
        if (status) query.status = status;
        if (confirmedBy) query.confirmedBy = confirmedBy;
        
        if (tabs === "orders") {
            delete query.idStore
            getReportsDelivery(query)
        } else if (tabs === "delivery_company") {
            getReportsDeliveryCompany(query)
        } else if (tabs === "products") {
            getReportsProducts(query)
        } else if (tabs === "money") {
            getReportsMoney(query)
        }
    }

    if (!["admin"].includes(role)) {
        return (
            <Container>
                <Stack align='center' justify='center' h={"calc(100vh - 130px)"}>
                    <IconLock size={45} strokeWidth={1.5} />
                    <Text>ليس لديك صلحيات للوصول لهذه الصفحة</Text>
                </Stack>
            </Container>
        )
    }

    return (
        <>
            <HeadPage
                page={"التقارير"}
                links={[
                    { title: t('categoriesPage.links.link01'), href: '/dashboard' },
                    { title: "التقارير", href: '' }
                ]}
                labelCreate={""}
                labelExport={""}
                onCreate={() => console.log()}
                onExport={() => console.log()}
                hiddenExport={true}
            />
            <Group justify='space-between' align='flex-end' mb={20}>
                <SegmentedControl
                    withItemsBorders={false}
                    value={tabs} onChange={(value) => navigate(`/dashboard/reports/${value}`)}
                    // value={value}
                    // onChange={setValue}
                    data={[
                        { label: "احصائيات الطلبيات", value: 'orders' },
                        { label: "احصائيات شركة التوصيل", value: 'delivery_company' },
                        { label: "احصائيات المنتجات", value: 'products' },
                        { label: "احصائيات الاموال", value: 'money' }
                    ]}
                    styles={{
                        root: {
                            border: "1px solid #E0E2E7",
                            background: "#fff",
                            height: 40,
                            alignItems: "center"
                        },
                        indicator: {
                            background: "#F2F7FB",
                            height: 30, minHeight: 30, maxHeight: 30,
                            boxShadow: "none"
                        }
                    }}
                    classNames={{
                        control: classes.control
                    }}
                />

                
                <Popover width={370} opened={openedPopover} onChange={setOpenedPopover} position="right-start" offset={10} withArrow closeOnClickOutside={false} >
                    <Popover.Target>
                        <ActionIcon variant='transparent' size={40} onClick={() => setOpenedPopover(true)}>
                            <IconFilter size={20} />
                        </ActionIcon>
                    </Popover.Target>
                    <Popover.Dropdown>
                        <form onSubmit={onSubmit(onFilter)}>
                            <Stack gap={5}>
                                <Group justify='space-between'>
                                    <Text size='sm' fw={600}>التاريخ :</Text>
                                    <DatePickerInput
                                        type="range"
                                        leftSection={<IconCalendar size={18} />}
                                        placeholder={"الفترة"}
                                        valueFormat='DD MMM YYYY'
                                        locale='ar'
                                        styles={{
                                            input: {width: 240}
                                        }}
                                        {...getInputProps("date")}
                                    />
                                </Group>
                                {tabs && ["orders"].includes(tabs)
                                    ? <Group justify='space-between'>
                                        <Text size='sm' fw={600}>المنتج :</Text>
                                        <Select
                                            leftSection={<IconBox size={18} />}
                                            placeholder={"المنتج"}
                                            data={products}
                                            styles={{
                                                input: {width: 240}
                                            }}
                                            {...getInputProps("productId")}
                                        />
                                    </Group>
                                    : null
                                }
                                {tabs && ["orders", "products"].includes(tabs)
                                    ? <Group justify='space-between'>
                                        <Text size='sm' fw={600}>الولاية :</Text>
                                        <Select
                                            leftSection={<IconMapPin size={18} />}
                                            placeholder={"الولاية"}
                                            data={allWilayas}
                                            styles={{
                                                input: {width: 240}
                                            }}
                                            {...getInputProps("state")}
                                        />
                                    </Group>
                                    : null
                                }
                                {tabs && ["orders", "products"].includes(tabs)
                                    ? <Group justify='space-between'>
                                        <Text size='sm' fw={600}>عمال التأكيد :</Text>
                                        <Select
                                            leftSection={<IconUser size={18} />}
                                            placeholder={"عامل التاكيد"}
                                            data={users}
                                            styles={{
                                                input: {width: 240}
                                            }}
                                            {...getInputProps("confirmedBy")}
                                        />
                                    </Group>
                                    : null
                                }
                                
                                <Group justify='flex-end' mt={10} gap={5}>
                                    <Button color="gray" size='xs' variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={() => setOpenedPopover(false)}>{"إلغاء"}</Button>
                                    <Button color={'#323232'} size='xs' rightSection={<IconFilter size={15} />} type="submit">{"تأكيد"}</Button>
                                </Group>
                            </Stack>
                        </form>
                    </Popover.Dropdown>
                </Popover>
            </Group>

            {tabs === "orders"
                ? <DeliveryReportsTable
                    data={reportsDelivery}
                    loading={false}
                />
                : tabs === "delivery_company"
                    ? <DeliveryCompanyReportsTable
                        data={reportsDeliveryCompany}
                        loading={false}
                    />
                    : tabs === "products"
                        ? <ProductsReportsTable
                            data={reportsProducts}
                            loading={false}
                        />
                        :  tabs === "money"
                            ? <MoneyReportsTable
                                data={[
                                    {"name": "المصاريف", "numberOrder": reportsMoney?.[0]?.totalCost || 0},
                                    {"name": "المبيعات", "numberOrder": reportsMoney?.[0]?.totalSales || 0}
                                ]}
                                loading={false}
                            />
                            : null
            }
        </>
    );
}