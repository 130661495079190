import {ActionIcon, Badge, Divider, Group, Image, Loader, Menu, Skeleton, Stack, Text} from '@mantine/core';
import {IconDotsVertical, IconEdit, IconTrash, IconCircleXFilled, IconCircleCheckFilled, IconEye, IconPencil, IconDatabaseOff} from '@tabler/icons-react';
import DataTable, {TableStyles} from 'react-data-table-component';
import dayjs from "dayjs";
import { useTranslation } from 'react-i18next';

const customStyles: TableStyles = {
    table: {
        style: {
            minHeight: 280,
            border: "2px solid #E0E2E7",
            borderRadius: "8px"
        },
    },
    headRow: {
        style: {
            borderRadius: "8px 8px 0 0",
            ":nth-child(1)": {
                borderRadius: "0 8px 0 0",
            },
            ":nth-last-child(1)": {
                borderRadius: "8px 0 0 0"
            }
        },
    },
    headCells: {
        style: {
            fontWeight: 800,
            fontSize: 14,
            background: "#F9F9FC",
            height: "56px",
            ":nth-child(1)": {
                background: "#F9F9FC",
            }
        }
    },
    cells: {
        style: {
            height: "50px"
        }
    }
};

type Props = {
    data?: any;
    loading?: boolean;
};

export const DeliveryCompanyReportsTable = ({data, loading}: Props) => {
    const { t } = useTranslation();
    const columns = [
        {name: "الحالة", selector: (row: any, index: number) => (
            <Group justify='flex-start' align='center' gap={8} wrap='nowrap'>{
                row?._id === "pending" ? <Text c='yellow'>{t('tables.orders.badge01')}</Text> 
                : row?._id === "confirmed" ? <Text c='green'>{t('tables.orders.badge02')}</Text> 
                    : row?._id === "closed" ? <Text c='red'>{t('tables.orders.badge03')}</Text>
                    : row?._id === "abandoned" ? <Text c='orange'>{t('tables.orders.badge04')}</Text>
                    
                        : row?._id === "no_answer" ? <Text c='yellow'>{"المحاولة الاولى"}</Text>
                        : row?._id === "no_answer_2" ? <Text c='yellow'>{"المحاولة الثانية"}</Text>
                        : row?._id === "no_answer_3" ? <Text c='yellow'>{"المحاولة الثالثة"}</Text>
                        : row?._id === "reported" ? <Text c='red'>{"إبلاغ"}</Text>
                        : row?._id === "duplicated" ? <Text c='orange'>{"مكرر"}</Text>
                        : row?._id === "phone_closed" ? <Text c='red'>{"الهاتف المغلق"}</Text>
                        : row?._id === "wrong_number" ? <Text c='orange'>{"الهاتف خطأ"}</Text>
                        : row?._id === "cancelled" ? <Text c='red'>{"ملغي"}</Text>
                        : row?._id === "fake_order" ? <Text c='red'>{"مزور"}</Text>
                        : row?._id === "delivred" ? <Text c='green'>{"تم التوصيل"}</Text>
                        : row?._id === "returned" ? <Text c='yellow'>{"مسترجع"}</Text>
                        : row?._id === "uploaded" ? <Text c='green'>{"تم الرفع"}</Text>
                        : row?._id === "returned_checked" ? <Text c='red'>{"مسترجع للتحقق"}</Text>
                        : row?._id === "shipping_company" ? <Text c='green'>{"عند شركة الشحن"}</Text>
                        : null
            }</Group>
        ), minWidth: "200px"},
        {name: "إجمالي المبيعات", selector: (row: any, index: number) => (
            <Badge variant="light" color="gray" radius="xs">{row?.totalSales} دج</Badge>
        ), minWidth: "110px", sortable: true, sortFunction: (a: any, b: any) => a?.totalSales > b?.totalSales ? 1 : -1 },
        {name: "صافي الربح", selector: (row: any, index: number) => (
            <Badge variant="light" color="gray" radius="xs">{row?.totalProfit} دج</Badge>
        ), minWidth: "110px", sortable: true, sortFunction: (a: any, b: any) => a?.totalProfit > b?.totalProfit ? 1 : -1 },
        {name: "صافي التكلفة", selector: (row: any, index: number) => (
            <Badge variant="light" color="gray" radius="xs">{row?.totalCost} دج</Badge>
        ), minWidth: "110px", sortable: true, sortFunction: (a: any, b: any) => a?.totalCost > b?.totalCost ? 1 : -1 }
    ];
   
    
   

    
    return (
        <DataTable
            // @ts-ignore
            columns={columns}
            data={data}
            customStyles={customStyles}
            highlightOnHover
            persistTableHead={true}
            progressPending={loading || false}
            progressComponent={<LoadingTable />}
            noDataComponent={
                <Stack align='center' justify='center' p={10}>
                    <Image src={"/emptyData.png"} mah={150} w={"auto"} />
                    <Text size='14px' c={"#667085"}>{t('tables.emptyLable')}</Text>
                </Stack>
            }
            pagination={false}
            paginationComponentOptions={{
                rowsPerPageText: t('tables.rowsPerPageText'),
                rangeSeparatorText: t('tables.rangeSeparatorText')
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 50, 75, 100, 200]}
            paginationPerPage={5}
        />
    );
};

const LoadingTable = () => {
    const numRows = [0,0,0];
    return (
        <Stack w={"100%"} p={0} m={0} gap={0}>
            {numRows.map((item, index) => (
                <RowSkeleton key={index}/>
            ))}
        </Stack>
    )
}

const RowSkeleton = () => {
    
    return (
        <Group w={"100%"} mih={48} align='stretch' gap={0} wrap='nowrap' style={{borderBottom: "2px solid #E0E2E7"}}>
            <Group h={60} miw={"25%"} px={16} justify='flex-start' align='center' gap={8} wrap='nowrap'>
                <Skeleton width={44} height={44} />
                <Stack justify='center' align='flex-start' gap={5}>
                    <Skeleton height={14} width={120} />
                    <Skeleton height={12} width={60} />
                </Stack>
            </Group>
            <Group h={60} miw={"18.75%"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"18.75%"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"18.75%"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
            <Group h={60} miw={"18.75%"} px={16} justify='flex-start' align='center'>
                <Skeleton height={13} width={60} />
            </Group>
        </Group>
    );
}