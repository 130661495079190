import { useCallback, useEffect, useState } from "react";
import { client } from "../lib/axiosClient";
import Cookies from 'universal-cookie';

const cookies = new Cookies(null, { path: '/' });

interface _Props {
    status: string;
    page: string | number;
    limit: string | number;
    sort: string | number;
    confirmedID?: string;

    productId?: string;
    dateStart?: string;
    dateEnd?: string;
    confirmedBy?: string;
}

export const useOrders = () => {
    const [data, setData] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    const fetchData = useCallback(async ({status, limit, page, sort, confirmedID, ...props}: _Props) => {
        setLoading(true);
        try {
            let params: {page: string | number, limit: string | number, sort: string | number, confirmedID?: string} = {
                page: page,
                limit: limit,
                sort: sort,
                ...props
            }
            if (confirmedID !== null && confirmedID !== "") {
                params = {...params, confirmedID}
            }
            const response = await client.get(`/ordersByStatus/${status}`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': cookies.get('token') || ""
                },
                params: params
            });
            
            if (response.status !== 200) {
                throw new Error('Failed to fetch data');
            }
            setData(response?.data)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }, []);
    
    const refetch = useCallback(({status, limit, page, sort, confirmedID, productId, dateStart, dateEnd, confirmedBy}: _Props) => {
        fetchData({status, limit, page, sort, confirmedID, productId, dateStart, dateEnd, confirmedBy});
    }, [fetchData]);

      
    return { data, loading, error, fetchData, refetch };
}