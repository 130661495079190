import { ActionIcon, Box, Button, Grid, Group, TagsInput, Text, TextInput, Tooltip } from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { client } from "../../lib/axiosClient";
import { IconCheck, IconSettings, IconX } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import Cookies from "universal-cookie";

import Communes from './../../helper/communes.json';
import { useTranslation } from "react-i18next";
const cookies = new Cookies(null, { path: '/' });

type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;

export const UpdateCommunes = ({data, ...props}: Props) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [allCommunesWilaya, setAllCommunesWilaya] = useState<{label: string, value: string}[]>([]);
    const {onSubmit, reset, getInputProps, setValues, values, insertListItem} = useForm({
        initialValues: {communes: []}
    });

    useEffect(() => {
        if (props.opened) {
            const communesWilaya = Communes.filter((item, index) => item.wilaya === parseInt(data.code))
            const newAllCommunesWilaya: {label: string, value: string}[] = []

            for (let i = 0; i < communesWilaya.length; i++) {
                const element = communesWilaya[i];
                newAllCommunesWilaya.push({label: element.name, value: element.name})
            }
            setAllCommunesWilaya([...newAllCommunesWilaya])
            setValues({
                communes: data?.communes || []
            })
        }
    }, [props.opened])

    const onSubmitForm = ({communes}: any) => {
        setLoading(true)
        
        client.put(`/delivery-price/updateWilaya/${data._id}`, {
            communes
        }, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: "تم تعديل بلديات الولاية", color: '#fff' });
            typeof props?.refetchData == "function" && props?.refetchData()
            setLoading(false)
            closeModal()
        })
        .catch((error) => {
            notifications.show({ message: "فشل تعديل بلديات الولاية", color: '#fff' });
            console.log(error)
            setLoading(false)
        });
    }

    const closeModal = () => {
        reset();
        props.onClose();
        setLoading(false)
    };

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading} size="md"
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form_communes">{t('modals.confirmation')}</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={{padding: 20}}>
                <form onSubmit={onSubmit(onSubmitForm)} id="submit_form_communes">
                    <Grid gutter={20} justify="flex-start">
                        <Grid.Col span={{base: 12}} style={{alignSelf: "flex-end"}}>
                            <TagsInput
                                placeholder={"البلديات"}
                                label={"البلديات"}
                                data={allCommunesWilaya}
                                
                                {...getInputProps(`communes`)}
                            />
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};