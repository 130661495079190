import React, { useEffect, useState } from 'react';
import { client } from '../../lib/axiosClient';
import { OrdersTabel } from '../../componants/Tables';
import {
    ChangeStatusMultiOrder,
    ChangeStatusOrder,
    ConfirmedOrder,
    DeleteMultiOrder,
    DeleteOrder,
    GetNewStatusMultiOrder,
    GetNewStatusOrder,
    SelectStatus,
    UpdateOrder
} from '../../componants/Modal';
import { HeadPage } from '../../componants';
import { ActionIcon, Button, Container, Dialog, Drawer, Group, Popover, SegmentedControl, Select, Stack, Text, TextInput, Tooltip } from '@mantine/core';
import { IconBox, IconCalendar, IconFilter, IconLock, IconLogout, IconMapPin, IconSearch, IconUser, IconX } from '@tabler/icons-react';
import classes from './../../styles/Product.module.css';
import { searchSortedData } from '../../lib/sort';
import Cookies from 'universal-cookie';
import { useLandingPage, useOrders, useProducts, useSearchOrders, useUsers } from '../../api';
import { useForm } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { DatePickerInput } from '@mantine/dates';
import XLSX from "sheetjs-style";
//@ts-ignore
import * as FileServer from "file-saver";
import dayjs from 'dayjs';
import { ShowOrderDrawer } from '../../componants/Drawer';
import { useTranslation } from 'react-i18next';

const cookies = new Cookies(null, { path: '/' });

export function Orders () {
    const { t } = useTranslation();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openShowModal, setOpenShowModal] = useState(false);
    const [openSelectStatus, setOpenSelectStatus] = useState(true);
    const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false);
    const [openConfirmedModal, setOpenConfirmedModal] = useState(false);
    const [openNewStatusModal, setOpenNewStatusModal] = useState(false);
    const [openMultiDeleteModal, setOpenMultiDeleteModal] = useState(false);
    const [openMultiChangeStatusModal, setOpenMultiChangeStatusModal] = useState(false);
    const [openNewStatusMultiModal, setOpenNewStatusMultiModal] = useState(false);
    const [selectedData, setSelectedData] = useState<any>(null);
    
    const [openedExportDialog, { close: closeExportDialog, open: openExportDialog }] = useDisclosure(false);

    const [search, setSearch] = useState('');

    const [orders, setOrders] = useState<any[]>([]);
    const [ordersSorted, setOrdersSorted] = useState<any[]>([]);

    const {onSubmit, reset, getInputProps, setValues, values} = useForm({
        initialValues: {
            productId: "", state: "", date: [], status: "", confirmedBy: ""
        }
    });
    const [meta, setMeta] = useState<any[]>([]);
    const [status, setStatus] = useState("pending");
    const [page, setPage] = useState<any>(1);
    const [limit, setLimit] = useState<any>(5);
    const [sort, setSort] = useState<any>(-1);
    
    const [exportType, setExportType] = useState("month");
    const [dateExport, setDateExport] = useState<any[]>([]);
    const [openedPopover, setOpenedPopover] = useState(false);
    const [products, setProducts] = useState<{label: string, value: string}[]>([]);
    const [users, setUsers] = useState<{label: string, value: string}[]>([]);

    const {loading, error, data: dataOrders, fetchData: grtOrders, refetch} = useOrders()
    const {loading: loadingSearch, data: dataSearch, fetchData: fetchSearch} = useSearchOrders()
    const {loading: loadingProducts, data: dataProducts} = useProducts({
        idStore: cookies.get("id") || ""
    })
    const {loading: loadingLandingProducts, data: dataLandingProducts} = useLandingPage({
        idStore: cookies.get("id") || ""
    })
    const {loading: loadingUsers, data: dataUsers} = useUsers()
    const [role, setRole] = useState("");

    useEffect(() => {
        if (cookies.get('role')) {
            setRole(cookies.get('role'))
        }
    }, [cookies.get('role')])

    useEffect(() => {
        grtOrders({
            limit, page, sort, status, confirmedID: cookies.get('role') === "confirmed" ? cookies.get('id') || "" : ""
        })
    }, [limit, page, sort, status])

    useEffect(() => {
        if (dataOrders) {
            setOrders(dataOrders.data)
            setOrdersSorted(dataOrders.data)
            setMeta(dataOrders.meta)
            setPage(dataOrders?.meta?.current_page)
        }
    }, [dataOrders])

    useEffect(() => {
        if (dataSearch && search !== "") {
            setOrdersSorted(dataSearch)
        }
    }, [dataSearch])

    useEffect(() => {
        if (search === "") {
            setOrdersSorted(orders)
        } else {
            fetchSearch(status, search)
        }
    }, [search])

    useEffect(() => {
        if (dataProducts) {
            const arrayProducts: {label: string, value: string}[] = []
            for (let i = 0; i < dataProducts?.length; i++) {
                const element = dataProducts[i];
                if (products.findIndex((item: any) => item.value === element?._id) < 0) {
                    arrayProducts.push({label: element?.name, value: element?._id})
                }
            }
            
            setProducts([...products, ...arrayProducts])
        }
    }, [dataProducts])

    useEffect(() => {
        if (dataLandingProducts) {
            const arrayLandingProducts: {label: string, value: string}[] = []
            for (let i = 0; i < dataLandingProducts?.length; i++) {
                const element = dataLandingProducts[i];
                if (products.findIndex((item: any) => item.value === element?._id) < 0) {
                    arrayLandingProducts.push({label: element?.name, value: element?._id})
                }
            }
            setProducts([...products, ...arrayLandingProducts])
        }
    }, [dataLandingProducts])

    useEffect(() => {
        if (dataUsers) {
            const arrayUsers: {label: string, value: string}[] = []
            for (let i = 0; i < dataUsers?.length; i++) {
                const element = dataUsers[i];
                if (arrayUsers.findIndex((item: any) => item.value === element?._id) < 0 && element?.role === "confirmed") {
                    arrayUsers.push({label: element?.name, value: element?._id})
                }
            }
            setUsers([...arrayUsers])
        }
    }, [dataUsers])

    const onFilter = ({productId, date, confirmedBy}: any) => {
        const query: any = {};
        if (productId) query.productId = productId;
        
        if (date && date?.length === 2 && date?.[0] && date?.[1]) {
            query.dateStart = dayjs(date?.[0]).format("YYYY-MM-DD")
            query.dateEnd = dayjs(date?.[1]).format("YYYY-MM-DD")
        }
        if (confirmedBy) query.confirmedBy = confirmedBy;
        
        grtOrders({
            limit, page, sort, status,
            ...query
        })
    }

    const onExport = () => {
        client.get(`/deliveryExport/${status}/${exportType}`, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            },
            params: {
                start: dateExport?.[0],
                end: dateExport?.[1]
            }
        })
        .then(async ({data}) => {
            closeExportDialog()
            setExportType("month")
            setDateExport([])
            await exportToExcel(data)
        })
        .catch((error) => {
            closeExportDialog()
            setExportType("month")
            setDateExport([])
            console.log(error);
        })
    }

    const exportToExcel = async (dataToExcel: any) => {
        let newDataToExcel = []
        for (let i = 0; i < dataToExcel.length; i++) {
            const element = dataToExcel[i];
            newDataToExcel.push({
                'المنتج': element?.product?.name,
                'اسم العميل': element?.fullName,
                'رقم الهاتف': element?.phone,
                'السعر': `${element?.price} ${t("currency")}`,
                'الكمية': element?.quantity,
                'حالة الطلب': element?.status === "pending" ? "قيد الانتظار" : element?.status === "confirmed" ? "مؤكد" : element?.status === "closed" ? "مرفوض" : element?.status === "abandoned" ? "عربة متروكة" : "",
                'تاريخ الطلب': dayjs(element?.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                'نوع التوصيل': element?.typeFee === "home_fee" ? "للمنزل" : "للمكتب",
                'الولاية': element?.state,
                'البلدية': element?.city,
                'العنوان': element?.address
            })
        }
        const ws = XLSX.utils.json_to_sheet(newDataToExcel);
        const wb = {Sheets: {'data': ws}, SheetNames: ['data']}
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;UTF-8"})

        FileServer.saveAs(data, `all-order-${dayjs().format("YYYY-MM-DD-HH:mm:ss")}.xlsx`)
    }

    if (!["admin", "confirmed"].includes(role)) {
        return (
            <Container>
                <Stack align='center' justify='center' h={"calc(100vh - 130px)"}>
                    <IconLock size={45} strokeWidth={1.5} />
                    <Text>ليس لديك صلحيات للوصول لهذه الصفحة</Text>
                </Stack>
            </Container>
        )
    }
    return (
        <>
            <HeadPage
                page={t('ordersPage.name')}
                links={[
                    { title: t('ordersPage.links.link01'), href: '/dashboard' },
                    { title: t('ordersPage.links.link02'), href: '' }
                ]}
                labelExport={t('ordersPage.labelExport')}
                onCreate={() => console.log()}
                onExport={openExportDialog}
            />

            <Group justify='space-between' align='flex-end' mb={20}>
                <Group justify='flex-start' align='center' wrap='nowrap' maw={"75%"}>
                    <SegmentedControl
                        withItemsBorders={false} 
                        value={status}
                        onChange={setStatus}
                        data={[
                            { label: t('ordersPage.tags.tag01'), value: 'pending' },
                            { label: t('ordersPage.tags.tag02'), value: 'confirmed' },
                            { label: t('ordersPage.tags.tag03'), value: 'closed' },
                            
                            { label: "المحاولة الاولى", value: 'no_answer' },
                            { label: "المحاولة الثانية", value: 'no_answer_2' },
                            { label: "المحاولة الثالثة", value: 'no_answer_3' },
                            { label: "إبلاغ", value: 'reported' },
                            { label: "مكرر", value: 'duplicated' },
                            { label: "الهاتف المغلق", value: 'phone_closed' },
                            { label: "الهاتف خطأ", value: 'wrong_number' },
                            { label: "ملغي", value: 'cancelled' },
                            { label: "مزؤر", value: 'fake_order' },
                            { label: "تم التوصيل", value: 'delivred' },
                            { label: "مسترجع", value: 'returned' },
                            { label: "تم الرفع", value: 'uploaded' },
                            { label: "مسترجع للتحقق", value: 'returned_checked' },
                            { label: "عند شركة الشحن", value: 'shipping_company' },
                            
                            { label: t('ordersPage.tags.tag04'), value: 'abandoned' },
                        ]}
                        styles={{
                            root: {
                                border: "1px solid #E0E2E7",
                                background: "#fff",
                                height: 40,
                                alignItems: "center",
                                overflowX: 'auto',
                                maxWidth: "100%"
                            },
                            indicator: {
                                background: "#F2F7FB",
                                height: 30, minHeight: 30, maxHeight: 30,
                                boxShadow: "none"
                            }
                        }}
                        classNames={{
                            control: classes.control
                        }}
                    />
                    <Tooltip label={"فلترة"} position='top'>
                        <Popover width={370} opened={openedPopover} onChange={setOpenedPopover} position="right-start" offset={10} withArrow closeOnClickOutside={false} >
                            <Popover.Target>
                                <ActionIcon variant='transparent' size={40} onClick={() => setOpenedPopover(true)}>
                                    <IconFilter size={20} />
                                </ActionIcon>
                            </Popover.Target>
                            <Popover.Dropdown>
                                <form onSubmit={onSubmit(onFilter)}>
                                    <Stack gap={5}>
                                        <Group justify='space-between'>
                                            <Text size='sm' fw={600}>التاريخ :</Text>
                                            <DatePickerInput
                                                type="range"
                                                leftSection={<IconCalendar size={18} />}
                                                placeholder={"الفترة"}
                                                valueFormat='DD MMM YYYY'
                                                locale='ar'
                                                styles={{
                                                    input: {width: 240}
                                                }}
                                                {...getInputProps("date")}
                                            />
                                        </Group>
                                        <Group justify='space-between'>
                                            <Text size='sm' fw={600}>المنتج :</Text>
                                            <Select
                                                leftSection={<IconBox size={18} />}
                                                placeholder={"المنتج"}
                                                data={products}
                                                styles={{
                                                    input: {width: 240}
                                                }}
                                                {...getInputProps("productId")}
                                            />
                                        </Group>
                                        <Group justify='space-between'>
                                            <Text size='sm' fw={600}>عمال التأكيد :</Text>
                                            <Select
                                                leftSection={<IconUser size={18} />}
                                                placeholder={"عامل التاكيد"}
                                                data={users}
                                                styles={{
                                                    input: {width: 240}
                                                }}
                                                {...getInputProps("confirmedBy")}
                                            />
                                        </Group>
                                        
                                        <Group justify='flex-end' mt={10} gap={5}>
                                            <Button color="gray" size='xs' variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={() => setOpenedPopover(false)}>{"إلغاء"}</Button>
                                            <Button color={'#323232'} size='xs' rightSection={<IconFilter size={15} />} type="submit">{"تأكيد"}</Button>
                                        </Group>
                                    </Stack>
                                </form>
                            </Popover.Dropdown>
                        </Popover>
                    </Tooltip>
                </Group>
                
                <TextInput
                    leftSectionPointerEvents="none"
                    leftSection={<IconSearch size={18} />}
                    placeholder={t('ordersPage.placeholder')}
                    styles={{
                        input: {height: 40}
                    }}
                    value={search}
                    onChange={(event) => setSearch(event.currentTarget.value)}
                />
            </Group>

            <OrdersTabel
                data={ordersSorted}
                setOpenDeleteModal={setOpenDeleteModal}
                setOpenEditModal={setOpenEditModal}
                setOpenShowModal={setOpenShowModal}
                setOpenChangeStatusModal={setOpenChangeStatusModal}
                setOpenConfirmedModal={setOpenConfirmedModal}
                setSelectedData={setSelectedData}
                setOpenNewStatusModal={setOpenNewStatusModal}
                setOpenMultiDeleteModal={setOpenMultiDeleteModal}
                setOpenMultiChangeStatusModal={setOpenMultiChangeStatusModal}
                setOpenNewStatusMultiModal={setOpenNewStatusMultiModal}
                refetchData={() => refetch({ limit, page, sort, status, confirmedID: cookies.get('role') === "confirmed" ? cookies.get('id') || "" : "" })}
                loading={loading || loadingSearch}
                meta={meta}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
            />

            <UpdateOrder title={t('ordersPage.updateOrder')}  data={selectedData} refetchData={() => refetch({ limit, page, sort, status, confirmedID: cookies.get('role') === "confirmed" ? cookies.get('id') || "" : "" })} opened={openEditModal} onClose={() => setOpenEditModal(false)} />
            <DeleteOrder data={selectedData} refetchData={() => refetch({ limit, page, sort, status, confirmedID: cookies.get('role') === "confirmed" ? cookies.get('id') || "" : "" })} opened={openDeleteModal} onClose={() => setOpenDeleteModal(false)} />
            <ChangeStatusOrder data={selectedData} refetchData={() => refetch({ limit, page, sort, status, confirmedID: cookies.get('role') === "confirmed" ? cookies.get('id') || "" : "" })} opened={openChangeStatusModal} onClose={() => setOpenChangeStatusModal(false)} />
            <ConfirmedOrder title={t('ordersPage.confirmedOrder')} data={selectedData} refetchData={() => refetch({ limit, page, sort, status, confirmedID: cookies.get('role') === "confirmed" ? cookies.get('id') || "" : "" })} opened={openConfirmedModal} onClose={() => setOpenConfirmedModal(false)} />
            <GetNewStatusOrder data={selectedData} refetchData={() => refetch({ limit, page, sort, status, confirmedID: cookies.get('role') === "confirmed" ? cookies.get('id') || "" : "" })} opened={openNewStatusModal} onClose={() => setOpenNewStatusModal(false)} />
            <DeleteMultiOrder data={selectedData} refetchData={() => refetch({ limit, page, sort, status, confirmedID: cookies.get('role') === "confirmed" ? cookies.get('id') || "" : "" })} opened={openMultiDeleteModal} onClose={() => setOpenMultiDeleteModal(false)} />
            <ChangeStatusMultiOrder data={selectedData} refetchData={() => refetch({ limit, page, sort, status, confirmedID: cookies.get('role') === "confirmed" ? cookies.get('id') || "" : "" })} opened={openMultiChangeStatusModal} onClose={() => setOpenMultiChangeStatusModal(false)} />
            <GetNewStatusMultiOrder data={selectedData} refetchData={() => refetch({ limit, page, sort, status, confirmedID: cookies.get('role') === "confirmed" ? cookies.get('id') || "" : "" })} opened={openNewStatusMultiModal} onClose={() => setOpenNewStatusMultiModal(false)} />
            
            <Dialog 
                opened={openedExportDialog} withCloseButton onClose={closeExportDialog}
                size={"lg"} radius="md" position={{ top: 70, right: 120 }}
            >
                <SegmentedControl
                    mt={10}
                    withItemsBorders={false} 
                    value={exportType}
                    onChange={setExportType}
                    data={[
                        { label: t('ordersPage.dialogExport.tags.tag01'), value: 'all' },
                        { label: t('ordersPage.dialogExport.tags.tag02'), value: 'month' },
                        { label: t('ordersPage.dialogExport.tags.tag03'), value: 'between' }
                    ]}
                    styles={{
                        root: {
                            border: "1px solid #E0E2E7",
                            background: "#fff",
                            height: 40,
                            alignItems: "center"
                        },
                        indicator: {
                            background: "#F2F7FB",
                            height: 30, minHeight: 30, maxHeight: 30,
                            boxShadow: "none"
                        }
                    }}
                    classNames={{
                        control: classes.control
                    }}
                />

                <Group w={"100%"} mt={20} justify='space-between' align="center" wrap='nowrap'>
                    {exportType === "between"
                        ? <DatePickerInput
                            type="range"
                            placeholder={t('ordersPage.dialogExport.placeholder')}
                            w={"100%"}
                            locale='ar'
                            //@ts-ignore
                            value={dateExport}
                            onChange={setDateExport}
                            popoverProps={{}}
                        />
                        : exportType === "all"
                            ? <Text size='sm'>{t('ordersPage.dialogExport.text01')}</Text>
                            : <Text size='sm'>{t('ordersPage.dialogExport.text02')}</Text>
                    }
                    
                    <Button 
                        variant={"outline"}
                        color={'#323232'} px={10} miw={70}
                        onClick={onExport}
                    >
                        {t('ordersPage.dialogExport.button')}
                    </Button>
                </Group>
            </Dialog>

            <ShowOrderDrawer title={t('ordersPage.showOrder')} data={selectedData} opened={openShowModal} onClose={() => setOpenShowModal(false)} />
            {/*<SelectStatus title={""} data={selectedData} opened={openSelectStatus} onClose={() => setOpenSelectStatus(false)} />*/}
        </>
    );
}